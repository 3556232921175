<template>
    <div class="seongdong_player_container">
        <Report
            v-if="isReport"
            :musicId="musicId"
            @closeModal="[(isReport = false), (isConfirmMusicReport = true)]"
            @closeModal2="isReport = false"
        />
        <ConfirmMusicReport v-if="isConfirmMusicReport" @closeModal="isConfirmMusicReport = false" />
        <Comment v-if="isComment" @closeModal="isComment = false" :musicInfo="channelMusicInfoObject" />
        <AlertModal v-if="isAlertModal" :alertText="alertText" @closeModal="closeAlertModal()" />
        <Like v-if="ShowLike" :active="ShowLike ? 'on' : ''" @closelike="onCloseLike" />
        <Hate v-if="ShowHate" :active="ShowHate ? 'on' : ''" @closehate="onCloseHate" />
        <h1 class="seongdong_spacetitle">
            {{ getSpaceNm }}<span class="seongdong_spacetitle_light">에</span> <br />
            <span class="seongdong_spacetitle_light">어울리는 음악을 재생 중 입니다.</span>
        </h1>
        <ul class="seongdong_curation_list">
            <li class="seongdong_curation_list_item item1 cursor">
                <label
                    class="seongdong_curation_item item1 cursor"
                    :class="activeTab === 'all' ? 'active' : ''"
                    @click="clickCurationTab('all')"
                >
                    <img
                        :src="
                            activeTab === 'all'
                                ? '/media/img/webCastPlayer/curation_setting_off.svg'
                                : '/media/img/webCastPlayer/curation_setting_on.svg'
                        "
                        alt="curation_setting_on"
                    />
                </label>
                <seongdong-cuarionall
                    v-if="activeTab === 'all'"
                    :sendcuration="sendCurationInfo"
                    :curation="curationInfoObject"
                    v-click-outside="onClickOutside"
                    @close-curationall="activeTab = ''"
                    @peroid-alert-modalrap="
                        [
                            (isAlertModal = true),
                            (alertText = `해외 랩/힙합 노래만 선호하시는 경우,<br />1980's 이외의 시대도 선택해주세요.`)
                        ]
                    "
                    @period-alert-modal-elec="
                        [
                            (isAlertModal = true),
                            (alertText = `해외 일렉트로니카 노래만 선호하시는 경우,<br />1980s, 1990s 이외의 시대도
      선택해주세요.`)
                        ]
                    "
                    @period-alert-modal-elec2="
                        [
                            (isAlertModal = true),
                            (alertText = `일렉트로니카 노래만 선호하시는 경우,<br /> 1980s, 1990s 이외의 시대도
      선택해주세요.`)
                        ]
                    "
                    @period-alert-modal-rapdomestic="
                        [
                            (isAlertModal = true),
                            (alertText = `랩/힙합 노래만 선호하시는 경우,<br /> 1980's 이외의 시대도 선택해주세요.`)
                        ]
                    "
                    @period-alert-modal-rap-elec="
                        [
                            (isAlertModal = true),
                            (alertText = `해외 랩/힙합, 일렉트로니카 노래만 선호하시는 경우,<br /> 1980's 이외의 시대도
      선택해주세요.`)
                        ]
                    "
                    @period-alert-modal-new="
                        [
                            (isAlertModal = true),
                            (alertText = `해외 뉴에이지 노래만 선호하시는 경우,<br /> 3개 이상의 시대를 선택해주세요.`)
                        ]
                    "
                    @period-alertmodal-new1980="
                        [
                            (isAlertModal = true),
                            (alertText = `뉴에이지 노래만 선호하시는 경우,<br /> 1980's, 1990's 이외의 시대도
      선택해주세요.`)
                        ]
                    "
                    @period-alert-modal-rnb="
                        [
                            (isAlertModal = true),
                            (alertText = `R&B/Soul 노래만 선호하시는 경우,<br /> 1980s 이외의 시대도 선택해주세요.`)
                        ]
                    "
                    @period-alert-modal-rnbnew="
                        [
                            (isAlertModal = true),
                            (alertText = `R&B/Soul, 뉴에이지 노래만 선호하시는 경우,<br /> 1980s 이외의 시대도
      선택해주세요.`)
                        ]
                    "
                    @period-alert-modal-jazz="
                        [
                            (isAlertModal = true),
                            (alertText = `재즈 노래만 선호하시는 경우,<br /> 1980s 이외의 시대도 선택해주세요.`)
                        ]
                    "
                    @GenreAlertModal="
                        [(isAlertModal = true), (alertText = `해외곡만 선호하시는 경우, 트로트는 선택 불가합니다.`)]
                    "
                />
            </li>
            <li class="seongdong_curation_list_item cursor">
                <label
                    class="seongdong_curation_item item2 cursor"
                    :class="activeTab === 'age' ? 'active' : ''"
                    @click="clickCurationTab('age')"
                >
                    <span class="seongdong_curation_name" :class="activeTab === 'age' ? 'active' : ''">{{
                        curationAgeGroup
                    }}</span>
                    <img
                        :class="activeTab === 'age' ? 'rotate' : ''"
                        :src="
                            activeTab === 'age'
                                ? '/media/img/webCastPlayer/arrow_black.svg'
                                : '/media/img/webCastPlayer/arrow_off.svg'
                        "
                        width="18"
                        height="18"
                        class="item_arrow"
                    />
                </label>
                <seongdong-curationage
                    v-if="activeTab === 'age'"
                    :ageGroup="curationInfoObject.ageGroup"
                    v-click-outside="onClickOutside"
                    @send-curation="onSendCuration"
                />
            </li>
            <li class="between_bar mlr"></li>
            <li class="seongdong_curation_list_item cursor">
                <label
                    class="seongdong_curation_item item3 cursor"
                    :class="activeTab === 'country' ? 'active' : ''"
                    @click="clickCurationTab('country')"
                >
                    <span class="seongdong_curation_name" :class="activeTab === 'country' ? 'active' : ''"
                    >국내 {{ curationInfoDomesticRate }}%</span
                    >
                    <img
                        :class="activeTab === 'country' ? 'rotate' : ''"
                        :src="
                            activeTab === 'country'
                                ? '/media/img/webCastPlayer/arrow_black.svg'
                                : '/media/img/webCastPlayer/arrow_off.svg'
                        "
                        width="18"
                        height="18"
                        class="item_arrow"
                    />
                </label>
                <seongdong-curationcountry
                    v-if="activeTab === 'country'"
                    v-click-outside="onClickOutside"
                    :domesticRate="curationInfoObject.domesticRate"
                    @send-curation="onSendCuration"
                />
            </li>
            <li class="between_bar mlr"></li>
            <li class="seongdong_curation_list_item cursor">
                <label
                    class="seongdong_curation_item item4 cursor"
                    :class="activeTab === 'genre' ? 'active' : ''"
                    @click="clickCurationTab('genre')"
                >
                    <span class="seongdong_curation_name" :class="activeTab === 'genre' ? 'active' : ''">{{
                        curationInfoGenre
                    }}</span>
                    <img
                        :class="activeTab === 'genre' ? 'rotate' : ''"
                        :src="
                            activeTab === 'genre'
                                ? '/media/img/webCastPlayer/arrow_black.svg'
                                : '/media/img/webCastPlayer/arrow_off.svg'
                        "
                        width="18"
                        height="18"
                        class="item_arrow"
                    />
                </label>
                <seongdong-curationgenre
                    v-if="activeTab === 'genre'"
                    :genrearray="curationInfoObject.genre"
                    v-click-outside="onClickOutside"
                    @send-curation="onSendCuration"
                />
            </li>
            <li class="between_bar mlr"></li>
            <li class="seongdong_curation_list_item cursor">
                <label
                    class="seongdong_curation_item item5 cursor"
                    :class="activeTab === 'period' ? 'active' : ''"
                    @click="clickCurationTab('period')"
                >
                    <span class="seongdong_curation_name" :class="activeTab === 'period' ? 'active' : ''">{{
                        curationInfoPeroid
                    }}</span>
                    <img
                        :class="activeTab === 'period' ? 'rotate' : ''"
                        :src="
                            activeTab === 'period'
                                ? '/media/img/webCastPlayer/arrow_black.svg'
                                : '/media/img/webCastPlayer/arrow_off.svg'
                        "
                        width="18"
                        height="18"
                        class="item_arrow"
                    />
                </label>
                <seongdong-curationpeorid
                    v-if="activeTab === 'period'"
                    :peroidarray="curationInfoObject.period"
                    v-click-outside="onClickOutside"
                    @send-curation="onSendCuration"
                />
            </li>
            <li class="between_bar mlr"></li>
            <li class="seongdong_curation_list_item cursor">
                <label
                    class="seongdong_curation_item item6 cursor"
                    :class="activeTab === 'emotion' ? 'active' : ''"
                    @click="clickCurationTab('emotion')"
                >
                    <span class="seongdong_curation_name" :class="activeTab === 'emotion' ? 'active' : ''">{{
                        curationEmotion
                    }}</span>
                    <img
                        :class="activeTab === 'emotion' ? 'rotate' : ''"
                        :src="
                            activeTab === 'emotion'
                                ? '/media/img/webCastPlayer/arrow_black.svg'
                                : '/media/img/webCastPlayer/arrow_off.svg'
                        "
                        width="18"
                        height="18"
                        class="item_arrow"
                    />
                </label>
                <seongdong-curationemotion
                    :emotionarray="curationInfoObject.emotion"
                    v-if="activeTab === 'emotion'"
                    v-click-outside="onClickOutside"
                    @send-curation="onSendCuration"
                />
            </li>
        </ul>

        <ul class="seongdong_player">
            <li class="seongdong_player_wrap">
                <div class="seondong_player_top">
                    <span class="seongdong_channel_icon">채널</span>
                    <span class="seongdong_channel_name">{{ getCurrentChannelInfo.spaceNm }}</span>
                    <div class="equalizer_wrap">
                        <ul class="equalizer_list" v-if="isPlay">
                            <li class="equalizer_item one"></li>
                            <li class="equalizer_item two"></li>
                            <li class="equalizer_item three"></li>
                            <li class="equalizer_item four"></li>
                            <li class="equalizer_item five"></li>
                        </ul>
                    </div>
                </div>
                <div class="seondong_player_middle">
                    <img
                        v-if="musicInfo.prevAlbumImg"
                        class="seondong_player_prevmusic_img img_border"
                        :src="`${musicInfo.prevAlbumImg}`"
                        alt=""
                    />
                    <div class="seondong_player_currentmusic_wrap">
                        <figure class="seondong_player_currentmusic_img">
                            <img class="img100 current_img_border" :src="`${musicInfo.albumImg}`" alt="" />
                        </figure>
                        <div class="seondong_player_musicinfo">
                            <h1 class="seongdong_music_name" v-rolling="{ str: musicInfo.musicNm, speed: 30, width: 236 }"></h1>
                            <h3 class="seongdong_artist" v-rolling="{ str: musicInfo.artist, speed: 30, width: 236 }"></h3>
                            <ul class="music_mange">
                                <li class="like_wrap cursor" @click="clickLike">
                                    <img
                                        id="like_img"
                                        :src="isLike ? '/media/img/like_icon_active.svg' : '/media/img/like_icon_off.svg'"
                                        alt="좋아요"
                                    />
                                </li>
                                <li class="unlike_wrap cursor" @click="clickHate">
                                    <img
                                        id="unlike_img"
                                        :src="isUnLike ? '/media/img/hate_icon_active.svg' : '/media/img/hate_icon_off.svg'"
                                        alt="싫어요"
                                    />
                                </li>
                                <li class="comment_wrap cursor" @click="comment">
                                    <img id="comment_img" src="/media/img/icon_pc_comment_30.svg" alt="좋아요" />
                                </li>
                                <li class="report_wrap cursor" @click="report">
                                    <img id="report_img" src="/media/img/icon_pc_report_30.svg" alt="좋아요" />
                                </li>
                            </ul>
                        </div>
                    </div>
                    <img class="seondong_player_nextmusic_img img_border" :src="`${musicInfo.nextAlbumImg}`" alt="" />
                </div>
                <div class="seondong_player_bottom">
                    <figure class="seondong_player_playwrap cursor" @click="playBtn">
                        <!-- /media/img/webCastPlayer/icon_play.svg-->
                        <img
                            :src="isPlay ? '/media/img/webCastPlayer/icon_pause.svg' : '/media/img/webCastPlayer/icon_play.svg'"
                            width="21"
                            height="26"
                        />
                    </figure>

                    <div class="music_control">
                        <!--mute.svg-->
                        <img
                            class="cursor mute_img"
                            :src="isMute ? '/media/img/ces/volume_off.png' : '/media/img/ces/volume_up.png'"
                            alt="volumup"
                            @click="isMute ? initVolume($store.getters['player/getVolume']) : initVolume(0)"
                        />
                        <div class="volume_range">
                            <input id="volumeRange" type="range" min="0" max="1" step="0.1" />
                        </div>
                    </div>
                </div>
            </li>
            <li class="seongdong_channellist_wrap">
                <div class="seongdong_currentchannel_wrap">
                    <h3 class="seongdong_currentchannel_title">현재 채널</h3>
                    <ul class="seongdong_currentchannel">
                        <li class="seongdong_currentchannel_item">
                            <figure class="channel_info_img_wrap">
                                <img class="channel_info_img img100 circle_wrap" :src="getCurrentChannelInfo.src" alt="" />
                            </figure>
                            <span class="seongdong_currentchannel_name">{{ getCurrentChannelInfo.spaceNm }}</span>
                            <p
                                class="hot xmas"
                                v-if="getCurrentChannelInfo.channelType === 'T' && getCurrentChannelInfo.isChistmas === 'Y'"
                            >
                                X-mas
                            </p>
                            <p v-else-if="getCurrentChannelInfo.channelType === 'T'" class="hot">Hot</p>
                        </li>
                        <li class="seongdong_currentchannel_item second">
                            <div class="equalizer_wrap">
                                <ul class="equalizer_list" v-if="isPlay">
                                    <li class="equalizer_item one"></li>
                                    <li class="equalizer_item two"></li>
                                    <li class="equalizer_item three"></li>
                                    <li class="equalizer_item four"></li>
                                    <li class="equalizer_item five"></li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="seongdong_listchannel_wrap">
                    <h3 class="seongdong_currentchannel_title">추천 채널</h3>
                    <ul class="channel_list">
                        <li
                            class="channel_list_item"
                            v-for="(item, index) in getRecomList"
                            :key="index"
                            @click="changeChannel(item.channelId)"
                        >
                            <ul class="seongdong_channel cursor">
                                <li class="seongdong_currentchannel_item">
                                    <figure class="channel_info_img_wrap">
                                        <img class="channel_info_img none img100 circle_wrap" :src="item.src" />
                                    </figure>
                                    <span class="seongdong_currentchannel_name">{{ item.spaceNm }}</span>
                                    <p class="hot xmas" v-if="item.channelType === 'T' && item.isChistmas === 'Y'">
                                        X-mas
                                    </p>
                                    <p v-else-if="item.channelType === 'T'" class="hot">Hot</p>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </li>
        </ul>
    </div>
</template>
<script>
import AlertModal from '@/components/modal/Space/AlertModal.vue';
import vClickOutside from 'v-click-outside';
import rolling from '@/utils/directive/rolling';
import { clone, cloneDeep } from 'lodash';
import axios from 'axios';
import { joinRecomChannel } from '@/service/api/webcastApi';
export default {
  name: 'SeongDongPlayer',

  data () {
    return {
      tabList: [
        { idx: 'curation1', classItem: 'item1' },
        { idx: 'curation2', classItem: 'item2' },
        { idx: 'curation3', classItem: 'item3' },
        { idx: 'curation4', classItem: 'item4' },
        { idx: 'curation5', classItem: 'item5' },
        { idx: 'curation6', classItem: 'item6' }
      ],
      activeTab: '',
      isAlertModal: false,
      alertText: '',
      curationInfoObject: {},
      sendCurationInfo: {},
      isMute: false,
      isComment: false,
      channelMusicInfoObject: {},
      isConfirmMusicReport: false,
      isReport: false,
      ShowHate: false,
      ShowLike: false
    };
  },
  directives: {
    clickOutside: vClickOutside.directive,
    rolling
  },
  components: {
    AlertModal: AlertModal,
    Comment: () => import(`@/components/modal/Player/Comment.vue`),
    Report: () => import(`@/components/modal/Player/Report.vue`),
    ConfirmMusicReport: () => import(`@/components/modal/Player/ConfirmMusicReport.vue`),
    Like: () => import(`@/components/modal/Player/Like.vue`),
    Hate: () => import(`@/components/modal/Player/Hate.vue`),
    'seongdong-cuarionall': () => import(`@/components/seongdongLibary/SeongDongCurationAll.vue`),
    'seongdong-curationage': () => import(`@/components/seongdongLibary/SeongDongCurationAge.vue`),
    'seongdong-curationcountry': () => import(`@/components/seongdongLibary/SeongDongCurationCountry.vue`),
    'seongdong-curationgenre': () => import(`@/components/seongdongLibary/SeongDongCurationGenre.vue`),
    'seongdong-curationpeorid': () => import(`@/components/seongdongLibary/SeongDongCurationPeorid.vue`),
    'seongdong-curationemotion': () => import(`@/components/seongdongLibary/SeongDongCurationEmotion.vue`)
  },

  async mounted () {
    await this.$nextTick();
    const inputRange = document.querySelector('#volumeRange');
    this.setBackground(inputRange, 0.5);
    if (inputRange) {
      inputRange.addEventListener('input', event => {
        // eslint-disable-next-line camelcase
        const gradient_value = 100 / event.target.attributes.max.value;
        // eslint-disable-next-line camelcase
        const volumeValue = parseInt(gradient_value * event.target.value);
        if (volumeValue === 0) {
          this.isMute = true;
        } else {
          this.isMute = false;
        }
        this.$VideoPlayer.setVolume(event.target.value);
        if (Number(event.target.value) !== 0) {
          this.$store.commit('player/setVolume', Number(event.target.value));
        }
        event.target.style.background =
          'linear-gradient(to right, #fff 0%, #fff ' +
          volumeValue +
          '%, hsla(0,0%,100%,.16) ' +
          volumeValue +
          '%, hsla(0,0%,100%,.16) 100%)';
      });
      inputRange.value = this.$store.getters['player/getVolume'];
      this.$VideoPlayer.setVolume(this.$store.getters['player/getVolume']);
      this.setBackground(inputRange, this.$store.getters['player/getVolume']);
      this.$store.commit('player/setVolume', this.$store.getters['player/getVolume']);
    }
  },

  methods: {
    async changeChannel (dstChannelId) {
      this.$store.commit('seongdongplayer/setLoadingStatus', true);
      this.$store.commit('seongdongplayer/setCurUpdate', 'change');
      const userId = this.$cookies.get('userId');
      const spaceId = this.$cookies.get('spaceId');
      const channelId = this.$store.state.channelId;
      try {
        const response = await joinRecomChannel(userId, spaceId, channelId, dstChannelId);
        const result = response.data.result;

        this.$store.commit('setChannelId', result.channelId);
        this.$store.commit('setChannelUrl', result.url);
      } catch (error) {
        console.error('joinRecomChannel');
      }
    },
    async playBtn () {
      if (this.$store.state.isPlay === '' || this.$store.state.isPlay === true) {
        this.$store.commit('setPlay', false);
        this.$VideoPlayer.onPause();
      } else {
        this.$store.commit('setPlay', true);
        const result = await fetch(this.$store.state.channelUrl);
        const { status } = result;
        if (status === 200) {
          this.$VideoPlayer.playerMusic(this.$store.state.channelUrl);
          this.$VideoPlayer.startLoad(-1);
          this.$VideoPlayer.onPlay();
        }
      }
    },
    retryChannelUrl (n) {},
    musicPlay () {},
    onSendCuration (value) {
      this.activeTab = '';

      this.activeTab = 'all';
      this.sendCurationInfo = value;
    },
    onClickOutside (target) {
      // 큐레이션 설정 팝업 이외의 장소 클릭 시 닫기
      if (target) {
        const { target: curTarget } = target;
        const parentList = this.getParentNode(curTarget);

        const find = parentList.find(item => item.className === 'single-file-alert alert');
        const curationBar = parentList.find(item => item.className === 'seongdong_curation_list');
        if (curTarget.className !== 'single-file-alert alert' && !find && !curationBar) {
          this.closeCuration(curTarget);
        }
      } else {
        this.closeCuration();
      }
    },
    async closeCuration (target = null) {
      await this.$nextTick();
      if (this.activeTab === 'all') {
        this.sendCurationInfo = {};
      }

      this.activeTab = '';
      if (target) {
        await this.$nextTick();
        target.click();
      }
    },
    getParentNode (target) {
      const nodes = [];
      while (target.parentNode) {
        nodes.unshift(target.parentNode);
        target = target.parentNode;
      }

      return nodes;
    },
    closeAlertModal () {
      this.isAlertModal = false;
    },
    async clickCurationTab (val) {
      await this.$nextTick();
      this.curationInfoObject = this.$store.getters['player/getCurationInfo'];

      if (val === this.activeTab) {
        this.activeTab = '';
      } else {
        this.activeTab = val;
      }
    },
    setLikeMusic () {
      const musicPath = this.$store.state.channelMusicInfo.musicId;
      const channelId = this.$store.getters['player/getChannelInfo'].channelId;
      const like = 'Y';
      const weatherCode = this.$store.getters['player/getWeatherInfo'].currentWeather;
      const spaceId = this.$cookies.get('spaceId');
      const aToken = this.$cookies.get('aToken');
      const headers = { 'X-AUTH-TOKEN': aToken };
      axios
        .post('/api/player/setLikeMusic', { spaceId, musicPath, like, weatherCode, channelId }, { headers })
        .catch(err => {
          console.log(err);
        });
    },
    clickLike () {
      // 좋아요 클릭
      this.ShowHate = false;
      this.setLikeMusic();
      const { like } = this.$store.getters.getLikeInfo;
      if (like === 'Y') {
        this.$store.commit('setLikeInfo', {
          like: 'N',
          unlike: null
        });

        this.ShowLike = false;
      } else {
        this.ShowLike = false;
        this.ShowLike = true;
        this.$store.commit('setLikeInfo', {
          like: 'Y',
          unlike: null
        });
      }
    },
    setUnlikeMusic () {
      const musicPath = this.$store.state.channelMusicInfo.musicId;
      const channelId = this.$store.getters['player/getChannelInfo'].channelId;
      const unlike = 'Y';
      const weatherCode = this.$store.getters['player/getWeatherInfo'].currentWeather;
      const spaceId = this.$cookies.get('spaceId');
      const aToken = this.$cookies.get('aToken');
      const headers = { 'X-AUTH-TOKEN': aToken };
      axios
        .post('/api/player/setLikeMusic', { spaceId, musicPath, unlike, weatherCode, channelId }, { headers })
        .catch(err => {
          console.log(err);
        });
    },
    clickHate () {
      this.ShowLike = false;
      this.setUnlikeMusic();
      const { unlike } = this.$store.getters.getLikeInfo;
      if (unlike === 'Y') {
        this.$store.commit('setLikeInfo', {
          like: null,
          unlike: 'N'
        });

        this.ShowHate = false;
      } else {
        this.ShowHate = false;
        this.ShowHate = true;
        this.$store.commit('setLikeInfo', {
          like: null,
          unlike: 'Y'
        });
      }
    },
    setBackground (ele, value = 0.1) {
      const standard = value;
      ele.value = value;
      // eslint-disable-next-line camelcase
      const gradient_value = 100 / ele.attributes.max.value;
      // eslint-disable-next-line camelcase
      ele.style.background = `linear-gradient(to right, #fff 0%, #fff ${gradient_value *
        // eslint-disable-next-line camelcase
        standard}%, hsla(0,0%,100%,.16)  ${gradient_value * standard}%, hsla(0,0%,100%,.16) 100%)`;
    },
    initVolume (value = 0.1) {
      this.isMute = !this.isMute;
      this.setBackground(document.querySelector('#volumeRange'), value);
      this.$VideoPlayer.setVolume(value);
    },
    comment () {
      this.channelMusicInfoObject = this.$store.getters.gettersChannelMusicInfo;
      this.isComment = true;
    },
    report () {
      this.isReport = true;
    },
    onCloseLike () {},
    onCloseHate () {}
  },
  computed: {
    isPlay () {
      return this.$store.getters.gettersIsPlay;
    },
    getSpaceNm () {
      return this.$store.getters.getSpaceNm;
    },
    curationInfo () {
      return this.$store.getters['player/getCurationInfo'];
    },
    curationInfoDomesticRate () {
      return this.$store.getters['player/getCurationInfo']
        ? this.$store.getters['player/getCurationInfo'].domesticRate
        : 0;
    },
    curationAgeGroup () {
      if (Object.keys(this.$store.getters['player/getCurationInfo']).length > 0) {
        const { ageGroup } = this.$store.getters['player/getCurationInfo'];
        return ageGroup;
      }
      // ageGroup
      return '';
    },
    curationInfoGenre () {
      if (Object.keys(this.$store.getters['player/getCurationInfo']).length > 0) {
        const { genre } = this.$store.getters['player/getCurationInfo'];
        if (genre.length > 1) {
          return `${genre[0]}+${genre.length - 1}`;
        } else {
          return genre[0];
        }
      }
      return '';
    },
    curationInfoPeroid () {
      if (Object.keys(this.$store.getters['player/getCurationInfo']).length > 0) {
        const { period } = this.$store.getters['player/getCurationInfo'];
        if (period.length > 1) {
          return `${period[0]}s+${period.length - 1}`;
        } else {
          return `${period[0]}s`;
        }
      }
      return '';
    },
    curationEmotion () {
      if (Object.keys(this.$store.getters['player/getCurationInfo']).length > 0) {
        const { emotion } = this.$store.getters['player/getCurationInfo'];
        if (emotion.length > 1) {
          return `${emotion[0]}+${emotion.length - 1}`;
        } else {
          return emotion[0];
        }
      }
      return '';
    },
    isLike () {
      const { like } = this.$store.getters.getLikeInfo;
      if (like === 'Y') {
        return true;
      }

      return false;
    },
    isUnLike () {
      const { unlike } = this.$store.getters.getLikeInfo;
      if (unlike === 'Y') {
        return true;
      }
      return false;
    },
    musicId () {
      if (this.$store.getters.gettersChannelMusicInfo) {
        return this.$store.getters.gettersChannelMusicInfo.musicId;
      }
      return '';
    },
    musicInfo () {
      if (this.$store.getters.gettersChannelMusicInfo) {
        return this.$store.getters.gettersChannelMusicInfo;
      }
      return {
        albumImg: '',
        artist: '',
        musicId: '',
        musicNm: '',
        nextAlbumImg: '',
        nextArtist: '',
        nextMusicId: '',
        nextMusicNm: '',
        prevAlbumImg: '',
        prevArtist: '',
        prevMusicId: '',
        prevMusicNm: ''
      };
    },
    getCurrentChannelInfo () {
      const cloneChannelInfo = clone(this.$store.getters['player/getChannelInfo']);
      // cloneChannelInfo.isChistmas = 'N';
      let src = '/media/img/cafe.png';
      if (cloneChannelInfo) {
        if (cloneChannelInfo.channelType === 'R') {
          src = '/media/img/webCastPlayer/recommend_channel.png';
        } else if (cloneChannelInfo.channelType === 'T') {
          if (cloneChannelInfo.spaceNm.includes('캐롤 채널')) {
            cloneChannelInfo.isChistmas = 'Y';
            src = '/media/img/webCastPlayer/chirstmas_thumnail.png';
          } else {
            src = '/media/img/webCastPlayer/top100_channel.png';
          }
        } else {
          if (cloneChannelInfo.sector === '카페') {
            src = '/media/img/cafe.png';
          } else if (cloneChannelInfo.sector === '음식점') {
            src = '/media/img/food.png';
          } else if (cloneChannelInfo.sector === '주점') {
            src = '/media/img/drink.png';
          } else if (cloneChannelInfo.sector === '병원') {
            src = '/media/img/hospital.png';
          } else if (cloneChannelInfo.sector === '편의시설') {
            src = '/media/img/Facilities.png';
          } else if (cloneChannelInfo.sector === '숙박시설') {
            src = '/media/img/thumnail_hotel.png';
          } else if (cloneChannelInfo.sector === '기타') {
            src = '/media/img/Group 485982.png';
          } else if (cloneChannelInfo.sector == null) {
            src = '/media/img/Group 485982.png';
          }
        }

        cloneChannelInfo.src = src;
        return cloneChannelInfo;
      }

      return {
        spaceNm: ''
      };
    },
    channelInfo () {
      if (this.$store.getters['player/getChannelInfo']) {
        return this.$store.getters['player/getChannelInfo'];
      }

      return {};
    },
    getRecomList () {
      let cloneRecommList = [];
      const recomList = cloneDeep(this.$store.getters['player/getRecomList']);
      const channelInfo = cloneDeep(this.$store.getters['player/getChannelInfo']);
      // 필터 R
      const filterR = recomList.filter(item => item.channelType === 'R');
      cloneRecommList = [...cloneRecommList, ...filterR];
      // 필터 T
      const filterT = recomList.filter(item => item.channelType === 'T');
      cloneRecommList = [...cloneRecommList, ...filterT];

      // 필터 B
      const filterB = recomList.filter(item => item.channelType === 'B');
      cloneRecommList = [...cloneRecommList, ...filterB];
      if (channelInfo) {
        if (channelInfo.channelType === 'B') {
          cloneRecommList = cloneRecommList.filter(item => item.channelId && item.spaceId !== channelInfo.spaceId);
        } else {
          cloneRecommList = cloneRecommList.filter(item => item.channelId && item.channelId !== channelInfo.channelId);
        }
      }

      // 이미지 삽입
      cloneRecommList.forEach(item => {
        item.isChistmas = 'N';
        let src = '/media/img/Group 485982.png';
        if (item.channelType === 'R') {
          src = '/media/img/webCastPlayer/recommend_channel.png';
        } else if (item.channelType === 'T') {
          if (item.spaceNm === '캐롤 채널') {
            item.isChistmas = 'Y';
            src = '/media/img/webCastPlayer/chirstmas_thumnail.png';
          } else {
            src = '/media/img/webCastPlayer/top100_channel.png';
          }
        } else {
          if (item.sector === '카페') {
            src = '/media/img/cafe.png';
          } else if (item.sector === '음식점') {
            src = '/media/img/food.png';
          } else if (item.sector === '주점') {
            src = '/media/img/drink.png';
          } else if (item.sector === '병원') {
            src = '/media/img/hospital.png';
          } else if (item.sector === '편의시설') {
            src = '/media/img/Facilities.png';
          } else if (item.sector === '숙박시설') {
            src = '/media/img/thumnail_hotel.png';
          } else if (item.sector === '기타') {
            src = '/media/img/Group 485982.png';
          } else if (item.sector == null) {
            src = '/media/img/Group 485982.png';
          }
        }

        item.src = src;
      });

      return cloneRecommList;
    }
  }
};
</script>
<style src="@/assets/css/seongdonglibary/seongdonglibaryplayer.css" scoped></style>
